import React from 'react';
import styled from 'styled-components';
import { useMe } from '@commons';
import { getFirstCaracts, cutLongText } from '@commons';
import { useHistory } from 'react-router-dom';

export type Props = {
  selected?: boolean;
  firstName?: string;
  lastName?: string;
};

type InitialProps = {
  initial?: string;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
`;

const InitialWrapper = styled.div`
  font-size: 21px;
  font-weight: 500;
  color: #003cc2;
  padding: 10px;
  background-color: #eaeefc;
  border-radius: 4px;
`;

const NameWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0 15px;
  overflow: hidden;
`;

export const Initial: React.FC<React.PropsWithChildren<InitialProps>> = ({
  initial,
}) => <InitialWrapper>{initial}</InitialWrapper>;

const reverseString = (str: string) => str.split('').reverse().join('');

export const UserButton: React.FC<React.PropsWithChildren<Props>> = () => {
  const { me } = useMe();
  const history = useHistory();
  const name = `${me?.firstName || ''} ${me?.lastName || ''}` || '';
  const initial = reverseString(getFirstCaracts(me as any));

  return (
    <Container
      style={{ cursor: 'pointer' }}
      onClick={() => history.push('/user/account')}
    >
      <Initial initial={initial} data-clarity-unmask="true" />
      {/* <NameWrapper>{cutLongText(name, 50)}</NameWrapper> */}
      {/*<SettingsIcon />*/}
    </Container>
  );
};
UserButton.displayName = 'UserButton';

export default UserButton;
