import { EFileType, IFile } from '@freelancelabs/teoreme-commons';
import {
  uploadFile,
  cutLongText,
  establishmentUseIban,
  extractVerifyRib,
  isNotEmpty,
} from '@commons';
import { printFormat } from 'iban';
import {
  isValidIBAN,
  isValidBIC,
  electronicFormatIBAN,
  friendlyFormatIBAN,
} from 'ibantools';
import { DownloadIcon } from 'components/icons';
import {
  Box,
  Cell,
  Divider,
  FileInput,
  FormControl,
  Grid,
  Input,
  Link,
  Spinner,
  Text,
} from 'components/ui';
import React from 'react';
import BlocInformation from 'components/ui/BlocInformation';

const grid = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '4',
  xl: '4',
};

type FormValues = any; //IBankAccount;

type PaiementFormProps = {
  onBoardingData?: any;
  onSubmit?: () => void;
  formInfos: any;
  form: FormValues & any;
  isLoadingRib: (value: boolean) => void;
};

export const PaiementForm = ({
  onBoardingData,
  formInfos,
  isLoadingRib,
  form: {
    formState: { errors, isDirty },
    register,
    control,
    setError,
    setValue,
    clearErrors,
    watch,
  },
}: PaiementFormProps) => {
  const establishmentForm = watch('establishment');
  const onBoardingEstablishment =
    onBoardingData?.establishment && onBoardingData?.establishment.length > 0
      ? onBoardingData?.establishment[0]
      : undefined;
  const [loadingFile, setLoadingFile] = React.useState(false);
  const [ribFile, setRibFile] = React.useState<File | null>(null);
  const [rib, setRib] = React.useState<IFile | null>(
    onBoardingEstablishment?.provider?.bankAccount?.rib
  );
  const iban = watch('iban');
  React.useEffect(() => {
    // on change siret reset files
    if (formInfos.displayForms === false) {
      setRibFile(null);
      setRib(null);
    }
  }, [formInfos]);

  const handleChangeRIB = async (files: File[]) => {
    setLoadingFile(true);
    isLoadingRib(true);
    setRibFile(files[0]);
    try {
      const s3Response = await uploadFile({
        file: files[0],
        //@ts-ignore
        fileType: EFileType.TEMPORARY,
        establishment: onBoardingEstablishment?.uuid || '',
        actionType: 'upload',
      });
      const rib = {
        fileName: s3Response?.fileName,
        fileLocation: s3Response?.fileLocation as string,
        eTag: s3Response?.eTag,
      };
      if (establishmentUseIban(onBoardingEstablishment || establishmentForm)) {
        const verifyFileData = await extractVerifyRib({
          fileLocation: rib?.fileLocation as string,
        });
        if (verifyFileData?.extractedIban && !isNotEmpty(iban)) {
          if (isValidIBAN(verifyFileData?.extractedIban)) {
            setValue(
              'establishment.provider.bankAccount.iban',
              friendlyFormatIBAN(verifyFileData?.extractedIban)
            );
          }
        }
      }
      setValue('establishment.provider.bankAccount.rib', rib);
      clearErrors('establishment.provider.bankAccount.rib');
      //setError('establishment.provider.bankAccount.rib', undefined);
    } catch (e) {
      //setValue('establishment.provider.bankAccount.rib', e);
    }
    setLoadingFile(false);
    isLoadingRib(false);
  };

  const checkIban = (value: string): string | undefined => {
    const iban = electronicFormatIBAN(value);
    if (iban && isValidIBAN(iban)) {
      return undefined;
    } else {
      return 'Le code IBAN est invalide ';
    }
  };
  const checkBIC = (value: string): string | undefined => {
    if (isValidBIC(value)) {
      return undefined;
    } else {
      return 'Le code BIC est invalide ';
    }
  };

  return (
    <Box mt={60} style={{ display: formInfos.displayForms ? '' : 'none' }}>
      <Divider />
      <Text mt={60} variant="h1">
        Coordonnées bancaires
      </Text>

      <Grid cols={12}>
        <Cell
          x-span={{
            xs: '12',
            sm: '12',
            md: '12',
            lg: '12',
            xl: '12',
          }}
        >
          <BlocInformation
            my={20}
            style={{ fontSize: '13px', fontWeight: 'bold' }}
          >
            <p>
              Si vous travaillez avec une société d’affacturage, merci de saisir
              son IBAN dans le champ ci-dessous.
            </p>
          </BlocInformation>
        </Cell>
        {establishmentUseIban(onBoardingEstablishment || establishmentForm) ? (
          <>
            <Cell x-span={grid}>
              <FormControl
                required
                label="Relevé d'identité bancaire"
                errorMessage={
                  errors?.establishment?.provider?.bankAccount?.rib?.message
                }
              >
                <FileInput
                  data-clarity-mask="True"
                  pr={20}
                  label=""
                  accept=".pdf"
                  subLabel=""
                  onSelect={handleChangeRIB}
                >
                  {loadingFile && <Spinner />}
                  <Link iconLeft={<DownloadIcon />}>
                    {ribFile || rib?.fileName
                      ? `Modifier le fichier ${
                          ribFile?.name
                            ? cutLongText(ribFile.name, 20)
                            : cutLongText(
                                rib && rib.fileName ? rib?.fileName : '',
                                20
                              )
                        }`
                      : 'Importer un document'}
                  </Link>
                </FileInput>
              </FormControl>
            </Cell>
            <Cell x-span={grid}>
              <FormControl
                required
                label="Iban"
                errorMessage={
                  errors?.establishment?.provider?.bankAccount?.iban?.message
                }
              >
                <Input
                  data-clarity-mask="True"
                  isFullWidth
                  pr={20}
                  {...(formInfos.displayForms
                    ? register('establishment.provider.bankAccount.iban', {
                        validate: (value: any) => checkIban(value),
                      })
                    : register('establishment.provider.bankAccount.iban', {
                        required: undefined,
                      }))}
                  onChange={e => {
                    // @ts-ignore
                    e.target.value = printFormat(e?.target?.value, ' ');

                    if (
                      // @ts-ignore
                      checkIban(printFormat(e?.target?.value, ' ')) ===
                      undefined
                    ) {
                      clearErrors('establishment.provider.bankAccount.iban');
                    }
                  }}
                />
              </FormControl>
            </Cell>
            <Cell x-span={grid}>
              <FormControl
                required
                label="BIC"
                errorMessage={
                  errors?.establishment?.provider?.bankAccount?.bic?.message
                }
              >
                <Input
                  data-clarity-mask="True"
                  isFullWidth
                  pr={20}
                  {...(formInfos.displayForms
                    ? register('establishment.provider.bankAccount.bic', {
                        validate: (value: any) => checkBIC(value),
                      })
                    : register('establishment.provider.bankAccount.bic', {
                        required: undefined,
                      }))}
                />
              </FormControl>
            </Cell>
            <Cell x-span={grid}>
              <FormControl
                required
                label="Nom de la banque"
                errorMessage={
                  errors?.establishment?.provider?.bankAccount?.bankName
                    ?.type === 'maxLength'
                    ? 'Veuillez saisir moins de 31 caractères'
                    : errors?.establishment?.provider?.bankAccount?.bankName
                        ?.message
                }
              >
                <Input
                  data-clarity-mask="True"
                  isFullWidth
                  pr={20}
                  {...(formInfos.displayForms
                    ? register('establishment.provider.bankAccount.bankName', {
                        required: 'Ce champ est obligatoire',
                        maxLength: 30,
                      })
                    : register('establishment.provider.bankAccount.bankName', {
                        required: undefined,
                      }))}
                />
              </FormControl>
            </Cell>
            <Cell x-span={grid}>
              <FormControl
                required
                label="Titulaire du compte"
                errorMessage={
                  errors?.establishment?.provider?.bankAccount?.owner?.message
                }
              >
                <Input
                  data-clarity-mask="True"
                  isFullWidth
                  pr={20}
                  {...(formInfos.displayForms
                    ? register('establishment.provider.bankAccount.owner', {
                        required: 'Ce champ est obligatoire',
                      })
                    : register('establishment.provider.bankAccount.owner', {
                        required: undefined,
                      }))}
                />
              </FormControl>
            </Cell>
          </>
        ) : (
          <Cell x-span={grid}>
            <FormControl
              required
              label="Relevé d'identité bancaire"
              errorMessage={
                errors?.establishment?.provider?.bankAccount?.rib?.message
              }
            >
              <FileInput
                data-clarity-mask="True"
                pr={20}
                label=""
                accept=".pdf"
                subLabel=""
                onSelect={handleChangeRIB}
              >
                {loadingFile && <Spinner />}
                <Link iconLeft={<DownloadIcon />}>
                  {ribFile || rib?.fileName
                    ? `Modifier le fichier ${
                        ribFile?.name
                          ? cutLongText(ribFile.name, 20)
                          : cutLongText(
                              rib && rib.fileName ? rib?.fileName : '',
                              20
                            )
                      }`
                    : 'Importer un document'}
                </Link>
              </FileInput>
            </FormControl>
          </Cell>
        )}
      </Grid>
    </Box>
  );
};

export default PaiementForm;
